import React from 'react'
import PropTypes from 'prop-types'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import useScrollTrigger from '@material-ui/core/useScrollTrigger'
import Slide from '@material-ui/core/Slide'

function HideOnScroll(props) {
  const { children, window } = props
  const trigger = useScrollTrigger({ target: window ? window() : undefined })

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  )
}

HideOnScroll.propTypes = {
  children: PropTypes.element.isRequired,
  window: PropTypes.func,
}

const NavBar = props => {
  return (
    <HideOnScroll {...props}>
      <AppBar style={{ backgroundColor: '#006BA6' }}>
        <Toolbar>
          <img id="logo" src={require('../../static/KP-logo.png')} alt="KP logo"></img>
        </Toolbar>
      </AppBar>
    </HideOnScroll>
  )
}

export default NavBar
