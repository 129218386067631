import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import Time from 'react-time'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Grid from '@material-ui/core/Grid'
import clockImg from '../../static/expires_clock.png'
import { disclaimerTitles, disclaimerTexts, disclaimerTextsApple, disclaimerIcons, consentList, consentListcms } from '../../common/constants'

/**
 * List of disclaimer title, images, and texts
 */
export default class DisclaimerList extends Component {
  constructor() {
    super()
    this.state = {
      disclaimerTitles: [],
      disclaimerTexts: [],
      consentList: [],
      consentListcms: [],
      time: '',
      disclaimerIcons: [],
      year: new Date().getFullYear()
    }
  }

  componentDidMount() {
    let date = new Date()
    date.setFullYear(date.getFullYear() + 1)
    this.setState({
      disclaimerTitles,
      disclaimerTexts: /appleKpxClient/gi.test(this.props.client.id) ? disclaimerTextsApple : disclaimerTexts,
      disclaimerIcons,
      time: date,
      consentList,
      consentListcms,
    })
  }

  /**
   * Parse through disclaimer arrays and center format them
   */
  createDisclaimerList() {
    let list = []
    let n = this.state.disclaimerTitles.length
    for (let i = 0; i < n; i++) {
      list.push(
        <div className="container disclaimerContainer" key={i}>
          <div className="row disclaimerImg">
            {this.state.disclaimerIcons[i].length > 0 && <img src={this.state.disclaimerIcons[i]} alt="id card"></img>}
          </div>
          <div>
            {this.state.disclaimerTitles[i].length > 0 && (
              <div className=" ">
                <strong>{this.state.disclaimerTitles[i]}</strong>
              </div>
            )}
            {this.state.disclaimerTexts[i].length > 0 && <div className=" ">{this.state.disclaimerTexts[i]}</div>}
          </div>
          <br />
        </div>
      )
    }
    return list
  }

  render() {
    if (this.props.client.isCMSClient) {
      return (
        <div>
          <Card className="disclaimerListContainer">
            <CardContent>
              <Typography color="textSecondary" gutterBottom>
                This app wants your permission to access the following information:
              </Typography>
              <Typography variant="h5" component="h2">
                <Grid item xs={12} md={6}>
                  <div>
                    <List className="disclaimerList">
                      {this.props.scopes.map((scope, index) => {
                        return (
                          <React.Fragment key={index}>
                            <ListItem>
                              <ListItemIcon>
                                <img src={scope.icon} alt="" width="32" />
                              </ListItemIcon>
                              <ListItemText primary={scope.desc} />
                            </ListItem>
                          </React.Fragment>
                        )
                      })}
                    </List>
                  </div>
                </Grid>
              </Typography>
              <Typography variant="body2" component="p">
                <span>This app will have access to your information until</span>
                <br />
                <strong>
                  <Time value={this.state.time} format="dddd MMMM Do YYYY, h:mm a" />
                </strong>
                <br />
                <br />
              </Typography>
            </CardContent>
          </Card>
        </div>
      )
    }

    else {

      return (
        <div>
          {this.createDisclaimerList()}
          <Card className="disclaimerListContainer">
            <CardContent>
              <Typography color="textSecondary" gutterBottom>
                <b>App:&nbsp;</b>"{this.props.client.description}" wants your permission to access the following information:
              </Typography>
              <Typography variant="h5" component="h2">
                <Grid item xs={12} md={6}>
                  <div>
                    <List className="disclaimerList">
                      {this.state.consentList.map(([value, img], index) => {
                        return (
                          <React.Fragment key={index}>
                            <ListItem>
                              <ListItemIcon>
                                <img src={img} alt="" width="32" />
                              </ListItemIcon>
                              <ListItemText primary={value} />
                            </ListItem>
                          </React.Fragment>
                        )
                      })}
                    </List>
                  </div>
                </Grid>
              </Typography>
              <Typography variant="h5" component="h2" className="clockContainer">
                <img src={clockImg} alt="" id="clockIcon"></img>
              </Typography>
              <Typography variant="body2" component="p">
                <span>This app will have access to your information until</span>
                <br />
                <strong>
                  <Time value={this.state.time} format="dddd MMMM Do YYYY, h:mm a" />
                </strong>
                <br />
                <br />
                <span>MyChart® licensed form Epic Systems Corporation,© 1999 – {this.state.year}.</span>
              </Typography>
            </CardContent>
          </Card>
        </div>
      )


    }
  }
}

DisclaimerList.propTypes = {
  disclaimerTitles: PropTypes.array,
  disclaimerImgs: PropTypes.array,
  disclaimerTexts: PropTypes.array,
}
