import React, { Component } from 'react'
import PropTypes from 'prop-types'
import apiCallerService from '../../common/api-caller.service'

import 'bootstrap/dist/css/bootstrap.min.css'
import '../../styles/main.css'

import Navbar from '../NavBar/NavBar'
import ProxyList from '../ProxyList/ProxyList'
import DisclaimerList from '../DisclaimerList/DisclaimerList'


import Popup from 'reactjs-popup'
import CssBaseline from '@material-ui/core/CssBaseline'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import Box from '@material-ui/core/Box'
import Toolbar from '@material-ui/core/Toolbar'
import Checkbox from '@material-ui/core/Checkbox'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import { Alert, AlertTitle } from '@material-ui/lab';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles'

import { url, consentPortalLegalLanguage, consentPortalResources, consentListcms } from '../../common/constants'


var resumePath = '';
var pfCookie = '';

const searchParams = new URLSearchParams(window.location.search);
const clientData = {
  id: searchParams.get('connectionId')
};
clientData.isEHRClient = clientData.id && /^appleKpxClient$|^kpx_ehr_/gi.test(clientData.id);
clientData.isCMSClient = clientData.id && !clientData.isEHRClient;
apiCallerService.client = clientData;


const kpTheme = createMuiTheme({
  palette: {
    primary: { main: '#0078B3' },
    secondary: { main: '#ffffff', contrastText: '#0078B3' },
  },
})

/**
 * Consent portal will contain navbar, legal verbiage, list of proxies,
 * disclaimer data, list of resources, read terms and conditions checkbox,
 * and the accept/proceed and deny buttons
 */
export default class ConsentPortal extends Component {
  constructor() {
    super()
    this.state = {
      userSelected: -1, // index for user selected in proxy list array
      userConsentedTo: [], // array of resources the user has selected
      hasAcceptedTerms: false, // read terms and conditions checkbox status
      xClientId: undefined,
      authToken: undefined,
      proxies: [],
      guid: '',
      legal: '',
      open: false, //popup modal status
      name: '',
    }

    this.handleOptionChange = this.handleOptionChange.bind(this)
    this.handleResourceChange = this.handleResourceChange.bind(this)
    this.handleButtonClick = this.handleButtonClick.bind(this)
    this.openModal = this.openModal.bind(this)
    this.closeModal = this.closeModal.bind(this)
  }

  async componentDidMount() {
    clientData.isEHRClient && await this.enrichClientDetails();
    const authToken = await this.getAuthToken();
    console.log("This is xClientId:", clientData);

    this.setState({
      authToken: authToken,
      xClientId: clientData.id,
    })

    if (this.props.proxies != null && this.props.proxies.length === 1) {
      this.setState({ userSelected: 0 })
    }


    if (clientData.isCMSClient) {
      const { guid, clientName, members: proxies, scopes, scopesDescriptions } = (await this.getProxies()) || {}
      const cmsScopes = consentListcms
        .filter(s => new RegExp(`\\b${s[0]}\\b`).test(scopes))
        .map(s => ({
          name: s[0],
          desc: scopesDescriptions[s[0]],
          icon: s[1]
        }));

      // temp hack since only one passed, find first match
      proxies.forEach(proxy => {
        const scope = cmsScopes.find(s => proxy[s.name]);
        if (scope) {
          proxy.claim = proxy[scope.name];
        }
      });

      this.setState({
        guid,
        clientName,
        proxies,
        legal: consentPortalLegalLanguage,
        resources: consentPortalResources,
        scopes: cmsScopes
      })
    }

    else {

      const { guid, members: proxies } = (await this.getProxies()) || {}

      this.setState({
        guid,
        proxies,
        legal: consentPortalLegalLanguage,
        resources: consentPortalResources,
      })

    }
  }

  async enrichClientDetails() {
    try {
      const adminApi = {
        code: 'ADMIN',
        url: `${url.pf_admin}/pf-admin-api/v1/oauth/clients?page=1&numberPerPage=10&filter=${clientData.id}`,
        method: 'get'
      }
      const adminResponse = await apiCallerService.makeServiceCall(adminApi);
      const item = adminResponse.data.items.find(i => i.clientId === clientData.id);
      clientData.description = item?.description || 'App';
    } catch (err) {
        console.log('Get Client Details Failed:' + JSON.stringify(err))
    }
  }

  async getAuthToken() {
    //change url bak to /kpxauth especially for apple
    try {
      const pingApi = {
        code: 'PING',
        url: url.auth,
        method: 'post',
      }
      const authTokenResponse = await apiCallerService.makeServiceCall(pingApi)

      return `${authTokenResponse.data.token_type} ${authTokenResponse.data.access_token}`
    } catch (err) {
      console.log('Anonymous Token Failure:' + JSON.stringify(err))
    }
  }

  async getProxies() {
    const query = window.location.search
    console.log("This is query");
    console.log(query);
    var query_split = query.split("REF");
    console.log("This is query split");
    console.log(query_split);
    const q = "?refId" + query_split[1];
    if (query_split[0].includes("%2F")) {
      resumePath = query_split[0].split("%2F")[2];
    }
    else {
      resumePath = query_split[0].split("%252F")[2];
    }

    console.log("This is resumePath", resumePath);


    if (clientData.isCMSClient) {

      const getProxiesApi = {
        code: 'CONSENT',
        url: `${url.cms_services}/getDataForConsentForm${q}`,
        method: 'get',
        xClientId: this.state.xClientId,
      }



      try {
        const proxiesResponse = await apiCallerService.makeServiceCall(getProxiesApi, this.state.authToken)
        return proxiesResponse.data
      } catch (err) {
        console.log('Get Proxies Failure:' + JSON.stringify(err))
        this.setState({
          apiError: true
        })
      }

    }
    else {
      const getProxiesApi = {
        code: 'CONSENT',
        url: `${url.services}/member/proxies${query}`,
        method: 'get',
        xClientId: this.state.xClientId,
      }


      try {
        const proxiesResponse = await apiCallerService.makeServiceCall(getProxiesApi, this.state.authToken)
        return proxiesResponse.data
      } catch (err) {
        console.log('Get Proxies Failure:' + JSON.stringify(err))
        this.setState({
          apiError: true
        })
      }

    }




  }

  /**
   * Set selected proxy's id from the proxy list
   * @param {number} selected
   */
  handleOptionChange = selected => {
    this.setState({
      userSelected: selected,
    })
  }

  /**
   * Set resources the user consented to
   * @param {array} resourcesConsented
   */
  handleResourceChange = resourcesConsented => {
    this.setState({
      userConsentedTo: resourcesConsented,
    })
  }

  /**
   * Set read terms and conditions checkbox condition
   * @param {*} event
   */
  handleReadTerms = event => {
    this.setState({
      hasAcceptedTerms: event.target.checked,
    })
  }

  /**
   * Pass user selection based on button clicked to the server
   * @param {string} buttonClicked
   */
  async handleButtonClick(buttonClicked) {
    switch (buttonClicked) {
      case 'ACCEPT':
        console.log("This is resumePath", resumePath);

        const arp = resumePath;
        var client = window.location.search.split('connectionId=')[1].split("&REF")[0];

        console.log(this.state.proxies);
        console.log(this.state.proxies[this.state.userSelected]);
        console.log(this.state.proxies[this.state.userSelected].id);
        console.log(this.state.guid);


        if (clientData.isCMSClient) {
          const consentRequestData = {
            "status": "accepted",
            "subject": this.state.proxies[this.state.userSelected].id,
            "actor": this.state.guid,
            "audience": client,
            "data": {
              "proxy": this.state.proxies[this.state.userSelected].proxy,
              "scopes": this.state.scopes.map(s => s.name).join(' ')
            }
          }

          try {
            const doConsentApi = {
              code: 'CONSENT',
              url: `${url.cms_services}/acceptConsent?resumePath=${arp}&pfCookieId=5TnZcRHAXHz9GhWuryMvfhpf0YwQHcmiNIsCuGkFDnr1`,
              method: 'post',
              xClientId: this.state.xClientId,
            }

            const doConsentResponse = await apiCallerService.makeServiceCall(
              doConsentApi,
              this.state.authToken,
              consentRequestData
            )

            console.log("this is response", doConsentResponse);
            console.log("these are the headers", doConsentResponse.headers);
            console.log("this iis the location", doConsentResponse.headers.Location);

            if (doConsentResponse.data.redirectUrl) {
              window.location = doConsentResponse.data.redirectUrl
            }

            if (doConsentResponse) {
              window.location = doConsentResponse.data.redirectUrl
              console.log("this is response", doConsentResponse);
              console.log("these are the headers", doConsentResponse.headers);
              console.log("this iis the location", doConsentResponse.headers.Location);
            }
          } catch (err) {
            console.log('Do Consent Failure:' + JSON.stringify(err))
            this.setState({
              apiError: true
            })
          }
        }
        else {

          console.log("inside apple accept consent logic");

          const consentRequestData = {
            guid: this.state.guid,
            consentingMember: this.state.proxies[this.state.userSelected],
          }

          console.log(consentRequestData);

          try {
            const doConsentApi = {
              code: 'CONSENT',
              url: `${url.services}/member/consent`,
              method: 'post',
              xClientId: this.state.xClientId,
            }

            console.log("This is doConsentApi");
            console.log(doConsentApi);

            console.log("This is auth token");
            console.log(this.state.authToken);

            const doConsentResponse = await apiCallerService.makeServiceCall(
              doConsentApi,
              this.state.authToken,
              consentRequestData
            )

            if (doConsentResponse.data.redirectUrl) {
              window.location = doConsentResponse.data.redirectUrl
            }
          } catch (err) {
            console.log('Do Consent Failure:' + JSON.stringify(err))
            this.setState({
              apiError: true
            })
          }
        }
        break
      case 'DENY':
        console.log("This is resumePath", resumePath);
        const drp = resumePath;



        if (clientData.isCMSClient) {
          const denyConsentRequestData = {
            "com.pingidentity.adapter.refid.external.application.failure.message": "consent declined"
          }

          try {
            const doConsentApi = {
              code: 'CONSENT',
              url: `${url.cms_services}/denyConsent?resumePath=${drp}`,
              method: 'post',
              xClientId: this.state.xClientId,
            }

            const denyConsentResponse = await apiCallerService.makeServiceCall(
              doConsentApi,
              this.state.authToken,
              denyConsentRequestData
            )

            if (denyConsentResponse.data.redirectUrl) {
              window.location = denyConsentResponse.data.redirectUrl
            }
          } catch (err) {
            console.log('Deny Consent Failure:' + JSON.stringify(err))
            this.setState({
              apiError: true
            })
          }
        }

        else {

          const denyConsentRequestData = {
            guid: this.state.guid,
            consentingMember: this.state.proxies[this.state.userSelected],
          }



          try {
            const doConsentApi = {
              code: 'CONSENT',
              url: `${url.services}/member/deny`,
              method: 'post',
              xClientId: this.state.xClientId,
            }

            const denyConsentResponse = await apiCallerService.makeServiceCall(
              doConsentApi,
              this.state.authToken,
              denyConsentRequestData
            )

            if (denyConsentResponse.data.redirectUrl) {
              window.location = denyConsentResponse.data.redirectUrl
            }
          } catch (err) {
            console.log('Deny Consent Failure:' + JSON.stringify(err))
            this.setState({
              apiError: true
            })
          }
        }


        break
      default:
        console.log('handleButtonClick called but something went wrong!')
        break
    }
  }

  openModal(action) {
    this.setState({ open: true })
    this.handleButtonClick(action)
  }

  closeModal() {
    this.setState({ open: false })
  }

  _onKeyUp = (ev) => {
    if (ev.key === 'Enter') {
      document.activeElement.click();
    }
  }



  render() {
    if (clientData.isCMSClient) {
      const { guid, clientName, proxies, name, legal, userSelected, hasAcceptedTerms, apiError, scopes } = this.state
      if (apiError) {
        return (
          <React.Fragment>
            <ThemeProvider theme={kpTheme}>
              <CssBaseline />
              <Navbar />
              <Toolbar />
            </ThemeProvider>
            <div className="alertMessageContainer">
              <Alert severity="error" variant="standard">
                <AlertTitle variant="h4">Error!</AlertTitle>
                <span>
                  Something went wrong internally... <br></br>
                  The problem is on our end, not yours. <br></br>
                  We'll get it back up and running.
                </span>

              </Alert>
            </div>
          </React.Fragment>
        )
      }
      if (!guid) {
        return (
          <React.Fragment>
            <ThemeProvider theme={kpTheme}>
              <CssBaseline />
              <Navbar />
              <Toolbar />
            </ThemeProvider>{ }
            <div className="center-spinner">
              <CircularProgress />
            </div>
          </React.Fragment>
        )
      } else {
        return (
          <React.Fragment>
            <ThemeProvider theme={kpTheme}>
              <CssBaseline />
              <Navbar />
              <Toolbar />
              <Container maxWidth="lg" className="contentContainer">
                <Box>
                  <center><h4><b><div className="styling-3 -book">The following third-party application ("App") has requested a transfer of the data types listed below from Kaiser Permanente to the App:<br></br><br></br> {clientName}</div></b></h4></center>
                  <br />
                  <center><img src={require('../../static/warning.png')} alt="" height="44" width="50">
                  </img> <b>Before approving access, we strongly encourage you to review the Terms of Use and Privacy Policy of the App that is requesting access.</b> </center>
                  <br />

                  <br />
                  <div className="styling-8 -book">

                    <div className="title">Please Be Aware</div><br></br>
                    Kaiser Permanente does not own, control, or manage the App listed above that is requesting the transfer of data. Kaiser Permanente cannot ensure the privacy or security of any data shared with or stored by the App.<br></br><br></br>
                    If your device is shared, lost, stolen, or otherwise accessed by others, data in the App may be viewable by others, and, if on-going transfer is enabled, data may continue to transfer to the App or App provider.<br></br><br></br>
                    Learn more about data sharing <a href="https://healthy.kaiserpermanente.org/support/health-record-access-update/third-party-app-privacy">best practices.</a><br></br><br></br><br></br>


                    <div className="title">If You Want to Remove Access, You May Do So in One of Three Ways</div><br></br>
                    1.	Manage your Kaiser Permanente <a href="https://healthy.kaiserpermanente.org/secure/third-party-access/health-data">data sharing preferences</a> in your account.<br></br><br></br>
                    2.	In the App (if such a feature is available).<br></br><br></br>
                    3.	Call the <a href="https://healthy.kaiserpermanente.org/support/health-record-access-update/third-party-app-privacy">Member Services number.</a><br></br><br></br>
                    Note the App may retain any data collected prior to the expiration or revocation of this consent or any data that was shared before it was removed from the App platform. This is determined by the App, not Kaiser Permanente.<br></br><br></br><br></br>

                    <div className="title">Data Transfer Details</div><br></br>
                    This release may either be a one-time or an on-going data transfer as determined by the App provider.
                    <br></br><br></br>
                    •	If this is a one-time transfer, the data provided to the App will not automatically update. If you want Kaiser Permanente to provide updated data to the App provider, you will need to authorize a new data transfer.<br></br><br></br>
                    •	If this is an on-going transfer, the data provided to the App will be automatically updated at a frequency set by the App for up to 365 days, unless you revoke or cancel the App's access.<br></br><br></br><br></br>

                    <div className="title">Terms & Conditions Requiring Your Approval</div><br></br>
                    The App has requested a transfer of the data elements listed below from Kaiser Permanente to the App.
                    <br></br><br></br>
                    By selecting "ACCEPT", you give Kaiser Permanente authorization to share your protected health information with the App, and with your employer if the App was made available by or for your employer. <b>Information about mental health and addiction treatment services, abortion and abortion-related services, and/or HIV test results and services you may have received will be transferred, if that type of information is included in the protected health information you have authorized to be transferred.</b>
                    <br></br><br></br>
                    By selecting "ACCEPT", the listed data elements will be transferred to the App consistent with the terms and conditions you have agreed to accept from the App provider.  You understand that Kaiser Permanente does not control the App or App provider, therefore, Kaiser Permanente is not responsible for the integrity, privacy, security, or breach of data once it is transferred to or stored in the App. Kaiser Permanente is also not responsible for additional uses or disclosures of the data by the App or App provider made after that data is received. Once information is sent to the App, it may no longer be protected by federal or state privacy laws, including but not limited to HIPAA.
                    <br></br><br></br>
                    You can cancel your authorization using the "Remove Access" method described above. This will stop Kaiser Permanente from sharing your health information to the App provider in response to subsequent requests from the App. Cancellation has no effect on information that was shared to the App prior to cancellation of the authorization.
                    <br></br><br></br>
                    Kaiser Permanente cannot make treatment, payment, or eligibility for benefits depend on whether this disclosure authorization is given or not. This authorization will be good for up to one year. You are entitled to a copy of this authorization upon request.
                    <br></br><br></br>
                    If you are a Kaiser Permanente member through a Self-Funded Plan sponsored by your employer, your employer's contract with KP may restrict us from sharing some or all of your Claims & Encounter data to the App. If your employer imposes such restrictions, you will see one of the following messages:
                    <br></br><br></br>
                    * - Partial Access: We are unable to provide access to some of the identified member's Claims & Encounter data due to Employer Group restrictions. <br /><br />
                    ** - No Access: We are unable to provide access to the identified member's Claims & Encounter data due to Employer Group restrictions. <br /><br />
                  </div>
                  <br />


                  {//loggedinuser has proxies (aside from himself/herself)
                    proxies != null && proxies.length > 0 && (
                      <ProxyList client={clientData} guid={guid} name={name} proxies={proxies} onChangeCallback={this.handleOptionChange} />
                    )}

                  {//loggedinuser has proxies and proxy selected has not consented yet
                    //loggedinuser has no proxies and has not consented yet
                    userSelected >= 0 && proxies != null && !proxies[userSelected].consent && <DisclaimerList client={clientData} scopes={scopes} />}
                  {proxies != null && userSelected >= 0 && !proxies[userSelected].consent && (
                    <div>
                      <div className="row justify-content-center">
                        <label htmlFor="agreement" >
                          <div onKeyUp={this._onKeyUp}>
                            <Checkbox
                              onChange={this.handleReadTerms}
                              color="primary"
                              inputProps={{
                                'id': 'agreement',
                                //'aria-label': 'secondary checkbox',
                              }}
                            />
                            I have read the statements above
                          </div>

                        </label>
                      </div>
                      <br />
                    </div>
                  )}

                  <div className="row justify-content-center" id="buttons">
                    <div className="accept-btn">
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => this.openModal('ACCEPT')}
                        disabled={!hasAcceptedTerms || userSelected < 0}
                      >
                        Accept
                      </Button>
                      <Popup open={this.state.open} closeOnDocumentClick onClose={this.closeModal}>
                        <span> Redirecting You to {clientName} </span>
                      </Popup>
                    </div>

                    <div className="deny-btn">
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => this.openModal('DENY')}
                        disabled={userSelected < 0}
                      >
                        Deny
                      </Button>
                    </div>
                    <br /><br />
                  </div>
                  <br /><br />
                </Box>
              </Container>
            </ThemeProvider>
          </React.Fragment>
        )
      }
    }
    else {
      const { guid, proxies, name, legal, userSelected, hasAcceptedTerms, apiError } = this.state
      if (apiError) {
        return (
          <React.Fragment>
            <ThemeProvider theme={kpTheme}>
              <CssBaseline />
              <Navbar />
              <Toolbar />
            </ThemeProvider>
            <div className="alertMessageContainer">
              <Alert severity="error" variant="standard">
                <AlertTitle variant="h4">Error!</AlertTitle>
                <span>
                  Something went wrong internally... <br></br>
                  The problem is on our end, not yours. <br></br>
                  We'll get it back up and running.
                </span>

              </Alert>
            </div>
          </React.Fragment>
        )
      }
      if (!guid) {
        return (
          <React.Fragment>
            <ThemeProvider theme={kpTheme}>
              <CssBaseline />
              <Navbar />
              <Toolbar />
            </ThemeProvider>{ }
            <div className="center-spinner">
              <CircularProgress />
            </div>
          </React.Fragment>
        )
      } else {
        return (
          <React.Fragment>
            <ThemeProvider theme={kpTheme}>
              <CssBaseline />
              <Navbar />
              <Toolbar />
              <Container maxWidth="lg" className="contentContainer">
                <Box>
                  <Typography variant="h4">Allow Access</Typography>
                  <Typography variant="subtitle1" gutterBottom>
                    {legal} <br />
                    <strong>
                      FOR CLARITY, YOUR USE OF THIS FUNCTIONALITY IS ALSO SUBJECT TO THE KAISER PERMANENTE WEBSITE TERMS
                      AND CONDITIONS
                    </strong>
                  </Typography>

                  {//loggedinuser has proxies (aside from himself/herself)
                    proxies != null && proxies.length > 0 && (
                      <ProxyList client={clientData} guid={guid} name={name} proxies={proxies} onChangeCallback={this.handleOptionChange} />
                    )}

                  {//loggedinuser has proxies and proxy selected has not consented yet
                    //loggedinuser has no proxies and has not consented yet
                    userSelected >= 0 && proxies != null && !proxies[userSelected].consent && <DisclaimerList client={clientData} />}
                  {proxies != null && userSelected >= 0 && !proxies[userSelected].consent && (
                    <div>
                      <div className="row justify-content-center">
                        <label id="agreement" htmlFor="agreement" >
                          <Checkbox
                            onChange={this.handleReadTerms}
                            color="primary"
                            inputProps={{
                              'aria-label': 'secondary checkbox',
                            }}
                          />
                          I have read the statements above
                        </label>
                      </div>
                      <br />
                    </div>
                  )}

                  <div className="row justify-content-center" id="buttons">
                    <div className="accept-btn">
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => this.openModal('ACCEPT')}
                        disabled={!hasAcceptedTerms || userSelected < 0}
                      >
                        Accept
                      </Button>
                      <Popup open={this.state.open} closeOnDocumentClick onClose={this.closeModal}>
                        <span> Redirecting You to {clientData.description} </span>
                      </Popup>
                    </div>

                    <div className="deny-btn">
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => this.openModal('DENY')}
                        disabled={userSelected < 0}
                      >
                        Deny
                      </Button>
                    </div>
                  </div>
                </Box>
              </Container>
            </ThemeProvider>
          </React.Fragment>
        )
      }



    }

  }
}

if (clientData.isCMSClient) {
  ConsentPortal.propTypes = {
    xClientId: PropTypes.string,
    authToken: PropTypes.string,
    guid: PropTypes.string,
    clientName: PropTypes.string,
    proxies: PropTypes.array,
    name: PropTypes.string,
    resources: PropTypes.array,
    legal: PropTypes.string,
  }
}
else {
  ConsentPortal.propTypes = {
    xClientId: PropTypes.string,
    authToken: PropTypes.string,
    guid: PropTypes.string,
    proxies: PropTypes.array,
    name: PropTypes.string,
    resources: PropTypes.array,
    legal: PropTypes.string,
  }
}
