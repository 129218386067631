import axios from 'axios'
import uuid from 'uuid'

const generateServiceHeaders = (client, api, authToken) => {
  let headers
  if (api.code === 'PING' || api.code === 'ADMIN') {
    headers = {
      //'Content-Type': 'application/x-www-form-urlencoded',
    }
  } else {
    const documentCookie = document.cookie

    var pfck = '';
    if (client.isCMSClient) {
      //new code
      console.log("This is document cookie");
      console.log(documentCookie);

      var pfck = documentCookie.split("PF=")[1];
    }
    else {
      let pfck
      if (documentCookie.includes('PF=')) {
        pfck = documentCookie.substring(
          documentCookie.indexOf('PF='),
          documentCookie.indexOf(';', documentCookie.indexOf('PF='))
        )
        if (pfck !== undefined) {
          pfck += '; Path=/; Domain=.kp.org'
        }
      }
    }


    headers = {
      'Content-Type': 'application/json',
      'x-correlationid': uuid.v4(),
      Authorization: authToken,
      pfck: pfck || document.cookie,
    }
  }

  return headers
}

class ApiCallerService {
  client;

  async makeServiceCall(api, authToken, data) {
    return axios.request({
      url: api.url,
      method: api.method,
      headers: generateServiceHeaders(this.client, api, authToken),
      data,
    })
  }
}

export default new ApiCallerService()
