import idcardImg from '../static/tou-whooffering.png'
import moneyImg from '../static/tou-funding.png'
import storageImg from '../static/tou-howretain.png'
import personImg from '../static/tou-whohasaccess.png'
import checklistImg from '../static/tou_recordwhoaccessed.png'
import trashImg from '../static/tou-candelete.png'
import folderImg from '../static/tou-howuse.png'
import allergiesImg from '../static/access_allergies.png'
import pcpImg from '../static/access_PCP.png'
import documentImg from '../static/access_documents.png'
import demographicsImg from '../static/access_demographics.png'
import socialHistoryImg from '../static/access_socialhistory.png'
import labResultsImg from '../static/access_labs.png'
import medicationsImg from '../static/access_medications.png'
import problemsImg from '../static/access_problems.png'
import proceduresImg from '../static/access_procedures.png'
import immunizationsImg from '../static/access_immunizations.png'
import vitalsImg from '../static/access_vitals.png'

export const url = {
  // Comment these for localhost
  services: '/kpxapi',
  auth: '/kpxauth',
  cms_services: '/kpxcmsapi',
  pf_admin: '/kpxadmin',
  baseUrl: 'https://kpx-service-bus-uat.kp.org/service/cdo/siae/healthplankpxv1rc/consent/api',
  //auth1: 'https://kpx-consent-uat.kp.org/kpxauth'
  // UnComment these for localhost
  // services: 'https://apiservice-bus-uat.kp.org/service/kpx/v1',
  // auth: 'https://pfam-uat.kp.org/as/token.oauth2?grant_type=client_credentials&scope=anonymousClientScope',
}

export const consentPortalLegalLanguage =
  'The mobile app identified below ("App") has requested a transfer of the data elements listed below from an electronic health record to the App. By selecting "I accept", the listed data elements will be transferred to the App as you have requested, and you agree: Kaiser Permanente does not control the App or App provider and is not responsible for the integrity, privacy, security or breach of data transferred or stored in the App, or the use or disclosure of data by the App or the App provider once the data is released by Kaiser Permanente. We encourage you to carefully review the terms of use and privacy policy and settings that apply to the App and the App provider and approve release of data only to those Apps and App providers that you trust. This release may either be a one-time or an on-going data transfer as determined by the App provider. If this is a one-time transfer, the data provided to the App will not be automatically updated (for example, if your conditions change). If you want Kaiser Permanente to provide updated health data to the App, you will need to authorize a new data transfer. If this is an on-going transfer, the data provided to the App will be automatically updated at the frequency and for the duration required by the App, unless you disable it or you reset your KP.org password. If your device is shared, lost, stolen, or otherwise accessed by others, data in the App may be viewable by others, and, if on-going transfer is enabled, data may continue to transfer to the App or App provider unless you disable it or you reset your KP.org password.'

export const consentPortalResources = [
  'Basic Demographic Information',
  'Allergy Intolerance Information',
  'Immunization Information',
  'Prescription Medication Information',
  'Healthcare Provider Information',
]

export const disclaimerTitles = [
  'Who is offering the app?',
  'How is this app funded?',
  'Where does this app save your data?',
  'Does the app developer allow you to obtain a record of the data that has been collected about you?',
  'Does the app developer allow you to obtain a record of the data that has been collected about you?',
  'Does this app allow you to delete the data that has been stored about you?',
  'How does the app developer use your data?',
  'Allow or deny access',
]

export const disclaimerTextsApple = [
  "Before allowing Apple to access your account, please be aware of the following important details. This information is provided by the creators of Apple Health. All mentions of the term 'data' hereafter refers to the data from your electronic health record.",
  "The app is not provided by your healthcare provider. This app is provided by Apple Inc., a for-profit organization that is not a HIPAA-covered entity or business associate of your healthcare provider, for purposes of this feature. Moving your data from your electronic health record to this app may move it out of a space protected by federal health privacy laws. The app will be able to use this data for any purposes permitted by your agreement with them. You should carefully review the app's privacy policy and statements for any privacy protections provided.",
  'This app is funded by the business ventures of Apple Inc.',
  'This app can save your data locally on your device. This app offers you an option to store data on servers, outside of your device.',
  'Other than you, the following people and groups may have access to the data that you provide this app consistent with the privacy policy and statements of Apple Inc.: People and groups you authorize, Apps that you authorize. You authorize access and sharing of your information generally. Circumstances and your user preferences may impact whether or not you will be notified.',
  'The app developer allows you to obtain a complete record of the data that has been collected about you.',
  'This app allows you to delete some of the data that it has stored about you, which may be based on your preferences. Keep in mind that even if the app has been deleted, the app cannot get back data provided to third parties. The app developer can continue to use your data according to any agreements you may have with them, even if you close your account and delete the app from your device. The privacy policy and statements of Apple Inc. contain more details.',
  "The app developer may use data about you to improve its services in the future. Apple Inc.'s privacy policy and statements may have more details on how and when the app uses your data.",
]

export const disclaimerTexts = [
  "Before allowing Third-Party Company to access your account, please be aware of the following important details. This information is provided by the creators of this app. All mentions of the term 'data' hereafter refers to the data from your electronic health record.",
  "The app is not provided by your healthcare provider. This app is provided by Third-Party Company, a for-profit organization that is not a HIPAA-covered entity or business associate of your healthcare provider, for purposes of this feature. Moving your data from your electronic health record to this app may move it out of a space protected by federal health privacy laws. The app will be able to use this data for any purposes permitted by your agreement with them. You should carefully review the app's privacy policy and statements for any privacy protections provided.",
  'This app is funded by the business ventures of Third-Party Company',
  'This app can save your data locally on your device. This app offers you an option to store data on servers, outside of your device.',
  'Other than you, the following people and groups may have access to the data that you provide this app consistent with the privacy policy and statements of Third-Party Company: People and groups you authorize, Apps that you authorize. You authorize access and sharing of your information generally. Circumstances and your user preferences may impact whether or not you will be notified.',
  'The app developer allows you to obtain a complete record of the data that has been collected about you.',
  'This app allows you to delete some of the data that it has stored about you, which may be based on your preferences. Keep in mind that even if the app has been deleted, the app cannot get back data provided to third parties. The app developer can continue to use your data according to any agreements you may have with them, even if you close your account and delete the app from your device. The privacy policy and statements of Third-Party Company contain more details.',
  "The app developer may use data about you to improve its services in the future. Third-Party Company's privacy policy and statements may have more details on how and when the app uses your data.",
]

export const disclaimerIcons = [
  idcardImg,
  moneyImg,
  storageImg,
  personImg,
  checklistImg,
  trashImg,
  folderImg,
  idcardImg,
]

export const consentList = [
  ['Allergies', allergiesImg],
  ['Primary Care Provider', pcpImg],
  ['Demographics', demographicsImg],
  ['Social History', socialHistoryImg],
  ['Lab Results', labResultsImg],
  ['Medications', medicationsImg],
  ['Problems', problemsImg],
  ['Procedures', proceduresImg],
  ['Immunizations', immunizationsImg],
  ['Vitals', vitalsImg],
]


export const consentListcms = [
  ['ExplanationOfBenefit', proceduresImg],
  ['BenefitClaims', documentImg]
]
