import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import ConsentPortal from './components/ConsentPortal/ConsentPortal'
import * as serviceWorker from './serviceWorker'

const root = document.getElementById('root')
root.setAttribute('data-version', '1.5.5')
ReactDOM.render(<ConsentPortal />, root)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
