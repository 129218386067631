import React, { Component } from 'react'
import PropTypes from 'prop-types'

/**
 * List of proxies via radio buttons
 */
export default class ProxyList extends Component {
  constructor() {
    super()
    this.state = {
      userSelected: -1,
    }
    this.selectedStyle = {
      //temp
      shadow: '0 8px 16px 0 rgba(0,0,0,0.2)',
      backgroundColor: 'blue',
    }
  }


  /**
   * Passes proxy selected to ConsentPortal
   * @param {number} event
   */
  handleOnChange = event => {
    this.setState({
      userSelected: event.target.value,
    })
    this.props.onChangeCallback(event.target.value)
  }


  _onKeyUp = (ev) => {
    if (ev.key === 'Enter') {
      document.activeElement.click();
    }
  }

  render() {
    if (this.props.client.isCMSClient) {
      return (
        <div className="m-4">
          <div className="row justify-content-center">
            <div className="styling-8 -book" id="select-user-access"> {`Please select whose data you want to access:`} </div>
          </div>
          <br />
          <br />
          <div className="row justify-content-center" role="group" aria-labelledby="select-user-access">
            <ul className="list-group" role="presentation">
              {this.props.proxies.map((value, index) => {
                return (
                  <li key={index}>
                    <label className="list-item" key={value.id}>
                      <div onKeyUp={this._onKeyUp}>
                        <input type="radio" name="subject" value={index} onChange={this.handleOnChange} />
                      </div>
                      <img
                        src={require('../../static/user.svg')}
                        alt=""
                        className="profile-pic m-4"
                      ></img>
                      <div className="patient-name"><div className="styling-8 -book">{value.name}{value.claim == "Partial access" && <div><i>*Partial access</i></div>}{value.claim == "No access" && <div><i>*No access</i></div>}</div></div>

                    </label>
                  </li>
                )
              })}
            </ul>
          </div>
          <br />
        </div>
      )
    }
    else {
      return (
        <div className="m-4">
          <div className="row justify-content-center">
            <h6>{`Please select whose data you want ${this.props.name} to access:`}</h6>
          </div>
          <div className="row justify-content-center">
            <ul className="list-group">
              {this.props.proxies.map((value, index) => {
                return (
                  <li key={value.id}>
                    <label className="list-item">
                      <input type="radio" name="subject" value={index} onChange={this.handleOnChange} />
                      <img
                        src={require('../../static/user-icon-temp.png')}
                        alt=""
                        className="profile-pic m-4"
                      ></img>
                      <div className="patient-name">{value.name}</div>
                    </label>
                  </li>
                )
              })}
            </ul>
          </div>
          <br />
        </div>
      )

    }

  }
}

ProxyList.propTypes = {
  proxies: PropTypes.array,
}
